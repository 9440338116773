@import "colors";
@import "responsive";

* {
  margin: 0; padding: 0;
  font: {
    family: 'Open Sans', sans-serif;
    size: 10pt;
  }
}
p {text-indent: 32px;}
ul, ol {text-indent: 16px; list-style-position: inside;}
a {
  color: $bluel;
  &:hover {color: $cinnabar}
  &:visited {color: $amethyst}
}
time {
  @media #{$standard} {color: $cinnabar}
}

html {
  min-width: 320px;
  max-width: 880px;
  @media #{$standard} {
    margin: 0 auto;
    min-width: 680px;
  }
}

header {
  font-weight: 700;
  position: relative;
  a#logo {
    display: block;
    position: absolute; left: 4px; top: 4px;
    width: 80px; height: 80px;
    background: {
      image: url(/img/logo-400.png);
      size: 100% 100%;
      repeat: no-repeat;
    }
    @media #{$standard} {
      width: 96px; height: 96px;
    }
  }
  nav {
    text-transform: uppercase;
    display: flex;
    margin-left: 104px;
    @media #{$standard} {
      justify-content: flex-end;
      margin-left: 128px;
      * {font-size: 11pt;}
    }
    span.title {display: none}
    a, a:visited, a:hover {
      color: $whale;
      display: block;
      padding: 7px 6px 2px 30px;
      text-decoration: none;
      background:{
        size: 24px;
        repeat: no-repeat;
        position: 4px 4px;
      }
      @media #{$standard} {
        span.abbr {display: none}
        span.title {display: block}
      }
      &.active {
        padding: 2px;
        width: 28px; height: 28px;
        background-color: $whale;
        span.abbr {display: none}
        @media #{$standard} {
          width: auto; height: auto;
          color: white;
          padding: 7px 8px 8px 30px;
          border-bottom: {left-radius: 8px; right-radius: 8px;}
          span.abbr {display: block}
          span.title {display: none}
        }
      }

      &.home {background-image: url(/img/home-48-whale.png)}
      &.home.active {background-image: url(/img/home-48-bgwhale.png)}
      &.pub {background-image: url(/img/pub-48-whale.png)}
      &.pub.active {background-image: url(/img/pub-48-bgwhale.png)}
      &.cv {background-image: url(/img/cv-48-whale.png)}
      &.cv.active {background-image: url(/img/cv-48-bgwhale.png)}
    }
    a:hover {
      background-color: $clouds;
      @media #{$standard} {border-bottom: {left-radius: 8px; right-radius: 8px;}}
    }
  }
  div.card {
    display: none;
    position: absolute;
    left: 20%; top: 0px;
    @media #{$standard} {
      display: flex;
      color: $silver;
      font: {size: 11pt; weight: 700; style: italic;}
      padding: 4px;
      &>div:first-child {
        text-indent: -32px;
      }
      .contacts {
        margin-left: 8px;
        padding-left: 8px;
        border-left: 1px solid $silver;
        a {
          color: $silver;
          display: block;
          padding-left: 22px;
          background: {
            image: url(/img/email-silver.png);
            size: 16px 16px;
            position: 2px 2px;
            repeat: no-repeat;
          }
        }
        [itemprop="email"] {background-image: url(/img/email-silver.png)}
        [itemprop="telephone"] {background-image: url(/img/phone-silver.png)}
        [itemprop="url"] {
          display: none;
          background-image: url(/img/globe-bluel.png);
        }
      }
    }
  }
  div.active, div.breadcrumps {
    text-transform: uppercase;
    background-color: $whale; color: white;
    padding: 8px 4px 8px 108px;
    a, a:visited, a:hover {color: white}
    @media #{$standard} {
      background: none;
      color: $whale;
      &.active {display: none}
      &.breadcrumps {
        position: absolute; top: 64px;
        * {font-size: 11pt}
        a {color: $whale}
      }
    }
  }
}
main {clear: both}
footer {
  clear: both;
  margin: 0 auto;
  color: $midnight;
  font: {style: italic; weight: 600;}
  text-align: center;
  div.card {
    margin: 0 auto;
    padding: 4px 0 16px 40px; width: 280px;
    opacity: 0.9;
    background: {
      image: url(/img/logo-400-clouds.png);
      size: 132px 132px;
      position: 95% 4px;
      repeat: no-repeat;
    }
    @media #{$standard} {
      padding-left: 105px;
    }
    @media #{$standard} {background-position: 100% 4px}

    & > div {
      text-align: left;
      margin: 0 auto;
    }
    a {
      color: $midnight;
      display: inline-block;
      padding-left: 24px;
      background: {
        size: 16px 16px;
        position: 2px 2px;
        repeat: no-repeat;
      }
    }
    a[itemprop="email"] {background-image: url(/img/email-midnight.png)}
    a[itemprop="telephone"] {background-image: url(/img/phone-midnight.png)}
  }
}

.gscholar {
  display: inline-block;
  width: 24px; height: 24px;
  vertical-align: middle;
  background:{
    size: 100%;
    repeat: no-repeat;
    position: 0 0;
    image: url(/img/gscholar.svg);
  }
}
.tw {
  display: inline-block;
  width: 24px; height: 24px;
  vertical-align: middle;
  background:{
    size: 100%;
    repeat: no-repeat;
    position: 0 0;
    image: url(/img/tw.png);
  }
}
.rg {
  display: inline-block;
  width: 32px; height: 32px;
  vertical-align: middle;
  background:{
    size: 100%;
    repeat: no-repeat;
    position: 0 0;
    image: url(/img/rg.png);
  }
}
.float-right {float: right}
.float-left {float: left}
.text-right {text-align: right}
.no-indent {text-indent: 0}
.no-hyphens {
  hyphens: none; -webkit-hyphens: none; -moz-hyphens: none; -ms-hyphens: none;
}
img.no-box {
  box-shadow: none!important;
  border: none;
}

// div.breadcrumbs {
//   padding: 4px 8px;
//   font-size: 12pt;
//   * {font-size: inherit; text-transform: uppercase;}
//   margin: 0px 0 0px 108px;
//   @media #{$standard}{margin: -24px 8px 4px 120px}
//
// }
div.pagination {
  margin: 8px;
  text-align: center;
  color: $whale;
    &.breadcrumbs {
      position: relative;
      div.menu {
        border: none;
        position: absolute;
        @media #{$mobile} {
          top: -44px; left: 140px; color: white;
        }
        @media #{$standard} {
          top: -44px; right: 0px;
        }
      }
    }
  div.menu {
    padding: 4px 8px;
    display: inline-block;
    font-size: 12pt;
    border: {top: 1px $whale solid; bottom: 1px $whale solid;}
    @media #{$standard}{display: block; font-size: 14pt;}
    span, a {
      font-size: inherit;
      display: inline-block;
      padding: 4px 8px;
      &:first-child {border: {top-left-radius: 2px; bottom-left-radius: 2px}}
      &:last-child {border: {top-right-radius: 2px; bottom-right-radius: 2px}}
    }
    span {
      color: white; background: $whale;
      border-left: solid 1px $whale;
    }
    a {
      color: $whale; background: $clouds;
      text-decoration: none;
      border-left: solid 1px $silver;
    }
    a:first-child, span+a {border-left-color: $clouds}
  }
} // menu.pagination
